import { animated, PickAnimated } from "react-spring";
import React, { useContext } from "react";
import LanguageContext from "./contexts/LanguageContext";

interface TitleProps {
  x: PickAnimated<any>;
  width: number;
  offset: number;
}

export const SubTitle = ({ x, offset, width }: TitleProps) => {
  const { getLabel } = useContext(LanguageContext);
  const subtitle1 = getLabel("subtitle1");
  const subtitle2 = getLabel("subtitle2");
  return (
    <animated.div
      key="subtitle-font"
      className="main-font"
      style={{
        overflow: "hidden",
        fontWeight: 600,
        fontSize: 16,
        opacity: x.to({
          range: [-1, 0, 1],
          output: [0, 1, 0],
        }),
        transform: x.to({
          range: [-1, 0, 1],
          output: [
            `translate(-${
              width / 2 - offset / 2
            }px, 0px) rotate(-90deg) scale(0.7)`,
            `translate(0px, 0px) rotate(0deg) scale(1)`,
            `translate(${
              width / 2 - offset / 2
            }px, 0px) rotate(90deg) scale(0.7)`,
          ],
        }),
      }}
    >
      {subtitle1}
      <br />
      {subtitle2}
    </animated.div>
  );
};
