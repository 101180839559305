import React from "react";

export const Description = ({
  children,
}: {
  children: JSX.Element | JSX.Element[] | string;
}): JSX.Element => {
  return (
    <div
      className={"secondary-font"}
      style={{
        pointerEvents: "none",
        flex: 0,
        position: "relative",
        width: "100%",
        height: "100%",
        fontSize: 20,
        letterSpacing: 0.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        //  padding: "0 80px",
        margin: "12px 0",
        fontWeight: 700,
      }}
    >
      <div style={{ padding: "0 75px" }}>{children}</div>
    </div>
  );
};
