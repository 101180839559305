export const enLabels = {
  backButton: "back",
  contactFormBackButton: "back",
  contactUsButton: "contact us!",
  subtitle1: "crafting web- and mobile apps",
  subtitle2: "based in the capital of austria",
  imADev: "i'm a dev!",
  realizeProject1: "i want to",
  realizeProject2: "realize a project!",
  devDescription1: "React, Node.js, etc. are your daily bread & butter?",
  devDescription2: "You are looking for new projects?",
  devDescription3: "Join us and become a part of something bigger",
  projectDescription1: "You have THE app idea?",
  projectDescription2: "We have the skills & the talents!",
  projectDescription3:
    "We've (almost) built it all: Real estate apps, buy-now-pay-later solutions, nutrition apps... the list is long...",

  projectDescription4: "Now it's time to add yours!",
};

export const deLabels = {
  backButton: "zurück",
  contactFormBackButton: "zurück",
  contactUsButton: "Schreib uns!",
  subtitle1: "schmiede für web- und mobile apps",
  subtitle2: "im herzen österreichs",
  imADev: "ich bin entwickler!",
  realizeProject1: "ich möchte ein",
  realizeProject2: "projekt verwirklichen!",
  devDescription1: "todo",
  devDescription2: "todo",
  devDescription3: "todo",
  projectDescription1: "todo",
  projectDescription2: "todo",
  projectDescription3: "todo",
  projectDescription4: "todo",
};
