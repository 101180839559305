import { DecisionButton } from "./DecisionButton";
import { animated, PickAnimated } from "react-spring";
import React, {useContext} from "react";
import { colors } from "./colors";
import LanguageContext from "./contexts/LanguageContext";

interface DevButtonProps {
  pathname: string;
  width: number;
  height: number;
  offset: number;
  x: PickAnimated<any>;
  y: PickAnimated<any>;
  navToDev: () => void;
}

export const DevButton = ({
  pathname,
  width,
  height,
  offset,
  x,
  y,
  navToDev,
}: DevButtonProps) => {
  const {getLabel}=useContext(LanguageContext)
  const imADev = getLabel("imADev")

  return (
    <animated.div
      key={"button-container-dev-button"}
      className="button-container"
      style={{
        position: "absolute",
        top: y.to({
          range: [-1, 0, 1],
          output: [
            height / 2 + offset * 1.75,
            height / 2 + offset * 1.75,
            0 + offset / 2,
          ],
        }),
        transform: x.to({
          range: [-1, 0, 1],
          output: [
            `translate(${width}px, 0px) scale(1)`,
            `translate(0px, 0px) scale(1)`,
            `translate(0px, 0px) scale(0.9)`,
          ],
        }),
      }}
    >
      <DecisionButton
        title={imADev}
        onClick={navToDev}
        hoveringPossible={pathname === "/start"}
        color={pathname === "/dev" ? colors.dev : undefined}
      />
    </animated.div>
  );
};
