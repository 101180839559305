import { animated, PickAnimated } from "react-spring";
import React, { useContext } from "react";
import { Description } from "./Description";
import LanguageContext from "./contexts/LanguageContext";
import { ContactUsButton } from "./ContactUsButton";

interface ProjectDescriptionProps {
  x: PickAnimated<any>;
  z: PickAnimated<any>;
  height: number;
  offset: number;
}

export const ProjectDescription = ({
  x,
  z,
  height,
  offset,
}: ProjectDescriptionProps) => {
  const { getLabel } = useContext(LanguageContext);
  const projectDescription1 = getLabel("projectDescription1");
  const projectDescription2 = getLabel("projectDescription2");
  const projectDescription3 = getLabel("projectDescription3");
  const projectDescription4 = getLabel("projectDescription4");

  return (
    <animated.div
      key={"description-container-project"}
      className="project-description"
      style={{
        pointerEvents: "none",
        width: "100%",
        height: "100%",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        top: x.to({
          range: [-1, 0, 1],
          output: [0, height + offset * 8, height + offset * 8],
        }),
        transform: z.to({
          range: [-1, 0, 1],
          output: [
            `translate(0%, 0%)`,
            `translate(100%, 0%)`,
            `translate(0px, -50%)`,
          ],
        }),
      }}
    >
      <Description>{projectDescription1}</Description>
      <Description>{projectDescription2}</Description>
      <Description>{projectDescription3}</Description>
      <Description>{projectDescription4}</Description>
      <ContactUsButton context={"project"} />
    </animated.div>
  );
};
