import { DecisionButton } from "./DecisionButton";
import { animated, PickAnimated } from "react-spring";
import React, { useContext } from "react";
import { colors } from "./colors";
import LanguageContext from "./contexts/LanguageContext";
import { useLocation, useNavigate } from "react-router-dom";

interface ContactUsButtonProps {
  context: "project" | "dev";
}

export const ContactUsButton = ({ context }: ContactUsButtonProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { selectedLanguage } = useContext(LanguageContext);
  const { getLabel } = useContext(LanguageContext);
  const contactUsButton = getLabel("contactUsButton");

  return (
    <div
      key={"button-container-contact-us-button"}
      className="button-container"
      style={{
        marginTop: 30,
      }}
    >
      <DecisionButton
        title={contactUsButton}
        onClick={() => {
          navigate(`/${context}/hello?lang=${selectedLanguage}`);
        }}
        hoveringPossible
        color={pathname.includes("/dev") ? colors.dev : colors.project}
      />
    </div>
  );
};
