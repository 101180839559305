import { animated, PickAnimated } from "react-spring";
import React from "react";
import { _allowedLanguages } from "./language";
import { LangButton } from "./LangButton";

interface LanguageButtonContainerProps {
  pathname: string;
  x: PickAnimated<any>;
  y: PickAnimated<any>;
  z: PickAnimated<any>;
  height: number;
  offset: number;
}

export const LanguageButtonContainer = ({
  x,
  y,
  z,
  height,
  offset,
  pathname,
}: LanguageButtonContainerProps) => {
  return (
    <animated.div
      key={"language-button-container-dev"}
      className="language-button-container"
      style={{
        position: "absolute",
        //pointerEvents: "none",
       // width: "25vw",
        height: 50,
        zIndex: 9999,
        border: "green 3px violet",
        //  backgroundColor: "violet",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "row",
        bottom: x.to({
          range: [-1, 0, 1],
          output: [0, 0, 0],
        }),
        // transform: z.to({
        //   range: [-1, 0, 1],
        //   output: [
        //     `translate(0%, -50%)`,
        //     `translate(-100%, -50%)`,
        //     `translate(0%, -50%)`,
        //   ],
        // }),
      }}
    >
      {_allowedLanguages.map((lang) => (
        <LangButton
          lang={lang}
          x={x}
          y={y}
          z={z}
          offset={offset}
          pathname={pathname}
        />
      ))}
    </animated.div>
  );
};
