import { animated, PickAnimated } from "react-spring";
import React from "react";
import { colors } from "./colors";

interface BlockBackgroundProps {
  x: PickAnimated<any>;
  y: PickAnimated<any>;
  width: number;
  height: number;
  offset: number;
}

export const BlockBackground = ({
  x,
  y,
  width,
  height,
  offset,
}: BlockBackgroundProps) => {
  // const blockWidth = offset * 1;
  const color = x.to({
    range: [-1, 0, 1],
    output: [colors.project, "black", colors.dev],
  });

  return (
    <animated.div
      style={{
        pointerEvents: "none",
        width: "80vw",
        position: "absolute",
        top: 0,
        height: x.to({
          range: [-1, 0, 1],
          output: [`30vh`, `0vh`, `30vh`],
        }),
        // backgroundColor: "pink",
        transform: x.to({
          range: [-1, 0, 1],
          output: [
            `translate(0px, 100%)`,
            `translate(0px, 0%)`,
            `translate(0px, 100%)`,
          ],
        }),
      }}
    >
      <animated.div
        style={{
          width: "100%",
          top: 0,
          position: "absolute",
          height: "100%",
          backgroundColor: color,
          opacity: 0.06,
          transform: y.to({
            range: [-1, 0, 1],
            output: [
              `translate(0px, 0px)`,
              `translate(0px, ${height}px)`,
              `translate(0px, 0px)`,
            ],
          }),
        }}
      />
      <animated.div
        style={{
          width: "100%",
          top: 0,
          position: "absolute",
          height: "50vh",
          backgroundColor: color,
          opacity: 0.06,
          transform: y.to({
            range: [-1, 0, 1],
            output: [
              `translate(50px, 70px)`,
              `translate(-100px, ${height / 2}px)`,
              `translate(50px, 70px)`,
            ],
          }),
        }}
      />
      <animated.div
        style={{
          width: "60vw",
          top: 0,
          position: "absolute",
          height: "55vh",
          backgroundColor: color,
          opacity: 0.06,
          transform: y.to({
            range: [-1, 0, 1],
            output: [
              `translate(150px, -20vh)`,
              `translate(150px, 20vh)`,
              `translate(150px, -20vh)`,
            ],
          }),
        }}
      />
    </animated.div>
  );
};
