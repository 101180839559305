import { useState } from "react";

export const DecisionButton = ({
  title,
  onClick,
  fontSize = 20,
  hoveringPossible = true,
  color,
  style = {},
}: {
  title: string | JSX.Element;
  onClick: () => void;
  fontSize?: number;
  hoveringPossible?: boolean;
  color: string | undefined;
  style?: object;
}) => {
  const [isHovered, setHovered] = useState(false);
  const isVisiblyHovered = hoveringPossible && isHovered;
  return (
    <button
      onClick={onClick}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      className="main-font"
      style={{
        pointerEvents: "auto",
        cursor: hoveringPossible ? "pointer" : "auto",
        display: "flex",
        flexDirection: "column",
        flex: 0,
        minWidth: 0,
        fontWeight: 800,
        fontSize,
        lineHeight: "20px",
        border: "none",
        //  height: 36,
        //  padding: 16,
        margin: "auto",
        marginTop: 4,
        backgroundColor: color ? color : "darkred",
        color: "white",
        ...style,
      }}
    >
      {title}
    </button>
  );
};
