import React from "react";
import "./App.css";
import Div100vh from "react-div-100vh";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Main } from "./Main";
import { LanguageContextProvider } from "./contexts/LanguageContext";

function App() {
  return (
    <Div100vh
      style={{
        overflow: "hidden",
        //border: "green 8px solid",
        boxSizing: "border-box",
      }}
    >
      <Router>
        <LanguageContextProvider>
          <Routes>
            <Route path="/" element={<Navigate to={"/start"} />} />
            <Route path="/start" element={<Main />} />
            <Route path="/dev" element={<Main />} />
            <Route path="/dev/hello" element={<Main />} />
            <Route path="/project" element={<Main />} />
            <Route path="/project/hello" element={<Main />} />
          </Routes>
        </LanguageContextProvider>
      </Router>
    </Div100vh>
  );
}

export default App;
