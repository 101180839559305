import React, { LegacyRef, useCallback, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useChain, useSpring, useSpringRef } from "react-spring";
import { useDimensions } from "./hooks/useDimensions";
import { ProjectDescription } from "./ProjectDescription";
import { DevDescription } from "./DevDescription";
import { CloseButton } from "./CloseButton";
import { ProjectButton } from "./ProjectButton";
import { DevButton } from "./DevButton";
import { Title } from "./Title";
import { SubTitle } from "./SubTitle";
import { BlockBackground } from "./BlockBackground";
import LanguageContext from "./contexts/LanguageContext";
import { LanguageButtonContainer } from "./LanguageButtonContainer";
import { ContactUsOverlay } from "./ContactUsOverlay";
import { Dimmer } from "./Dimmer";

const offset = 30;

export const Main = () => {
  const [ref, { width, height }] = useDimensions({ liveMeasure: false });
  const { selectedLanguage } = useContext(LanguageContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const refOne = useSpringRef();
  const refTwo = useSpringRef();
  const refThree = useSpringRef();

  const { x: showContactUs } = useSpring({
    from: { x: 0 },
    x: pathname.includes("/hello") ? 1 : 0,
    config: { duration: 300 },
  });
  const { x } = useSpring({
    from: { x: 0 },
    x: pathname.includes("/dev") ? 1 : pathname.includes("/project") ? -1 : 0,
    config: { duration: 300 },
    ref: refOne,
  });
  const { x: y } = useSpring({
    from: { x: 0 },
    x: pathname.includes("/dev") ? 1 : pathname.includes("/project") ? -1 : 0,
    config: { duration: 300 },
    ref: refTwo,
  });
  const { x: z } = useSpring({
    from: { x: 0 },
    x: pathname.includes("/dev") ? 1 : pathname.includes("/project") ? -1 : 0,
    config: { duration: 300 },
    ref: refThree,
  });
  useChain([refOne, refTwo, refThree]);

  const navToRoot = useCallback(() => {
    navigate(`/start?lang=${selectedLanguage}`);
  }, [selectedLanguage]);
  const navToDev = useCallback(() => {
    navigate(`/dev?lang=${selectedLanguage}`);
  }, [selectedLanguage]);
  const navToProject = useCallback(() => {
    navigate(`/project?lang=${selectedLanguage}`);
  }, [selectedLanguage]);

  return (
    <div
      key={"app"}
      ref={ref as LegacyRef<HTMLDivElement>}
      className="App"
      style={{
        position: "relative",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <BlockBackground
        y={y}
        x={x}
        offset={offset}
        width={width}
        height={height}
      />
      <Title x={x} offset={offset} width={width} />
      <SubTitle x={x} offset={offset} width={width} />
      <DevButton
        height={height}
        width={width}
        x={x}
        y={y}
        offset={offset}
        pathname={pathname}
        navToDev={navToDev}
      />
      <ProjectButton
        height={height}
        width={width}
        x={x}
        y={y}
        offset={offset}
        pathname={pathname}
        navToProject={navToProject}
      />
      <CloseButton
        pathname={pathname}
        x={x}
        navToRoot={navToRoot}
        offset={offset}
      />
      <DevDescription x={x} z={z} height={height} offset={offset} />
      <ProjectDescription x={x} z={z} height={height} offset={offset} />
      <LanguageButtonContainer
        pathname={pathname}
        x={x}
        y={y}
        z={z}
        height={height}
        offset={offset}
      />
      <Dimmer show={showContactUs} />
      <ContactUsOverlay show={showContactUs} />
      <style>{`
        div, p, button {
            -webkit-tap-highlight-color: transparent;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        `}</style>
    </div>
  );
};
