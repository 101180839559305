import { FormEvent, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

const MIN_REQUIRED_TIMESPAN = 30 * 1000; // 30 seconds

interface ContactFormProps {
  handleClose: () => void;
}

type Status = "SUCCESS" | "POST_ERROR" | "TIME_ERROR";
export interface InfoState {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export const ContactForm = ({ handleClose }: ContactFormProps) => {
  const { pathname } = useLocation();
  const side = pathname.includes("/dev") ? "dev" : "project";
  const formRef = useRef(null);
  const [infoState, setInfoState] = useState<InfoState>({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [status, setStatus] = useState<Status>();
  const [lastSubmit, setLastSubmit] = useState<Date>();

  const handleChange = (fieldName: string, value: string) => {
    setInfoState((state) => ({ ...state, [fieldName]: value }));
  };

  if (status === "SUCCESS") {
    return (
      <FormContainer header={"Danke für deine Nachricht!"}>
        <p>
          <button onClick={handleClose}>Zurück zur Webseite</button>
        </p>
      </FormContainer>
    );
  }

  if (status === "POST_ERROR") {
    return (
      <FormContainer header={"Ein Fehler ist aufgetreten!"}>
        <p>
          <button
            onClick={() => {
              setStatus(undefined);
            }}
          >
            Erneut versuchen
          </button>
        </p>
        <p>
          <button onClick={handleClose}>Zurück zur Webseite</button>
        </p>
      </FormContainer>
    );
  }

  if (status === "TIME_ERROR") {
    return (
      <FormContainer
        header={"Bitte warte ein wenig bevor du eine erneute Anfrage schickst."}
      >
        <p>
          <button
            onClick={() => {
              setStatus(undefined);
            }}
          >
            Erneut versuchen
          </button>
        </p>
        <p>
          <button onClick={handleClose}>Zurück zur Webseite</button>
        </p>
      </FormContainer>
    );
  }

  const { name, email, subject, message } = infoState;

  const handleSubmit = (e: FormEvent<HTMLFormElement>, info: InfoState) => {
    e.preventDefault();

    if (
      lastSubmit &&
      new Date().getTime() - lastSubmit.getTime() < MIN_REQUIRED_TIMESPAN
    ) {
      console.log(`Form Submit - Time Error`);
      setStatus("TIME_ERROR");
      return;
    }

    const form = formRef.current;

    const baseUrl = process.env.REACT_APP_CONTACT_FORM_BASE_URL;
    const contactFormUrl = `${baseUrl}/contact/contact`;

    console.log("DEBUG contact form url", contactFormUrl);

    fetch(contactFormUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // @ts-ignore
        "form-name": form.getAttribute("name"),
        receiver: `vienna-devs-${side}`,
        name: info.name,
        email: info.email,
        subject: info.subject,
        message: info.message,
      }),
    })
      .then((response) => {
        console.log(
          `Form Submit - Success: ${JSON.stringify(response, null, 2)}`
        );

        setStatus("SUCCESS");
        setLastSubmit(new Date());
      })
      .catch((error) => {
        console.log(`Form Submit - Post Error: ${error}`);
        setStatus("POST_ERROR");
      });
  };

  const readyToSubmit = (() => {
    if (!formRef || !formRef.current) {
      return true;
    }
    // @ts-ignore
    const formInputs = Array.prototype.slice.call(formRef.current.elements);
    return formInputs.some((i) => i.validity.valid === false);
  })();

  return (
    <FormContainer header={"Ich freue mich über deine Nachricht!"}>
      <form
        onSubmit={(e) => handleSubmit(e, infoState)}
        name="contact"
        action="/"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        className="form"
        ref={formRef}
      >
        {/* ===== bot-field ===== */}
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <input
              name="bot-field"
              onChange={(event) =>
                handleChange("bot-field", event.target.value)
              }
            />
          </label>
        </p>
        {/* ===== name-field ===== */}
        <p>
          <label>
            Name
            <br />
            <input
              type="text"
              placeholder="Name"
              id="name"
              name="name"
              required
              value={name}
              onChange={(event) => handleChange("name", event.target.value)}
            />
          </label>
        </p>
        {/* ===== email-field ===== */}
        <p>
          <label>
            Email
            <br />
            <input
              type="email"
              placeholder="Email"
              id="email"
              name="email"
              required
              value={email}
              onChange={(event) => handleChange("email", event.target.value)}
            />
          </label>
        </p>
        {/* ===== subject-field ===== */}
        <p>
          <label>
            Betreff
            <br />
            <input
              type="text"
              placeholder="Betreff"
              id="subject"
              name="subject"
              required
              value={subject}
              onChange={(event) => handleChange("subject", event.target.value)}
            />
          </label>
        </p>
        {/* ===== message-field ===== */}
        <p>
          <label>
            Nachricht
            <br />
            <textarea
              placeholder="Nachricht"
              id="message"
              name="message"
              required
              rows={4}
              value={message}
              onChange={(event) => handleChange("message", event.target.value)}
            />
          </label>
        </p>
        {/* ===== submit-button ===== */}
        <div>
          <button
            type="submit"
            name="submit"
            value="Submit"
            disabled={readyToSubmit}
          >
            Nachricht absenden!
          </button>
        </div>
      </form>
    </FormContainer>
  );
};

interface FormContainerProps {
  header: string;
  children: JSX.Element | JSX.Element[];
}

const FormContainer = ({ header, children }: FormContainerProps) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      flex: 1,
     // height: "100%",
      padding: 16,
    }}
  >
    <h2>Kontakt</h2>
    <h3>{header}</h3>
    {children}
  </div>
);
