import { Description } from "./Description";
import { animated, PickAnimated } from "react-spring";
import React, { useContext } from "react";
import LanguageContext from "./contexts/LanguageContext";
import { ContactUsButton } from "./ContactUsButton";

interface DevDescriptionProps {
  x: PickAnimated<any>;
  z: PickAnimated<any>;
  height: number;
  offset: number;
}

export const DevDescription = ({
  x,
  z,
  height,
  offset,
}: DevDescriptionProps) => {
  const { getLabel } = useContext(LanguageContext);
  const devDescription1 = getLabel("devDescription1");
  const devDescription2 = getLabel("devDescription2");
  const devDescription3 = getLabel("devDescription3");

  return (
    <animated.div
      key={"description-container-dev"}
      className="dev-description"
      style={{
        pointerEvents: "none",
        width: "100%",
        height: "100%",
        //  border: "green 3px violet",
        //  backgroundColor: "violet",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        top: x.to({
          range: [-1, 0, 1],
          output: [height * 2, height * 2, height / 2],
        }),
        transform: z.to({
          range: [-1, 0, 1],
          output: [
            `translate(0%, -50%)`,
            `translate(-100%, -50%)`,
            `translate(0%, -50%)`,
          ],
        }),
      }}
    >
      <Description>{devDescription1}</Description>
      <Description>{devDescription2}</Description>
      <Description>{devDescription3}</Description>
      <ContactUsButton context={"dev"} />
    </animated.div>
  );
};
