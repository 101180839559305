import { DecisionButton } from "./DecisionButton";
import { animated, PickAnimated } from "react-spring";
import React, { useContext } from "react";
import { colors } from "./colors";
import LanguageContext from "./contexts/LanguageContext";

interface ProjectButtonProps {
  pathname: string;
  width: number;
  height: number;
  offset: number;
  x: PickAnimated<any>;
  y: PickAnimated<any>;
  navToProject: () => void;
}

export const ProjectButton = ({
  pathname,
  width,
  height,
  offset,
  x,
  y,
  navToProject,
}: ProjectButtonProps) => {
  const { getLabel } = useContext(LanguageContext);
  const realizeProject1 = getLabel("realizeProject1");
  const realizeProject2 = getLabel("realizeProject2");

  return (
    <animated.div
      key={"button-container-project-button"}
      className="button-container"
      style={{
        position: "absolute",
        top: y.to({
          range: [-1, 0, 1],
          output: [
            0 + offset / 2,
            height / 2 + offset * 2.75,
            height / 2 + offset * 2.75,
          ],
        }),
        transform: x.to({
          range: [-1, 0, 1],
          output: [
            `translate(0px, 0px) scale(0.9)`,
            `translate(0px, 0px) scale(1)`,
            `translate(-${width}px, 0px) scale(1)`,
          ],
        }),
      }}
    >
      <DecisionButton
        title={
          <p style={{ margin: 0 }}>
            {realizeProject1}
            <br />
            {realizeProject2}
          </p>
        }
        onClick={navToProject}
        hoveringPossible={pathname === "/start"}
        color={pathname === "/project" ? colors.project : undefined}
      />
    </animated.div>
  );
};
