import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  _allowedLanguages,
  allowedLanguages,
  languageFallback,
} from "../language";
import { deLabels, enLabels } from "../i18n/labels";

interface LanguageContextProps {
  selectedLanguage?: string;
  setSelectedLanguage: (newLanguage: string) => void;
  getLabel: (labelKey: string) => string;
}

const getBrowserLanguage = () => {
  return navigator?.language?.split("-")?.[0] || languageFallback;
};

const LanguageContext = React.createContext<LanguageContextProps>(
  {} as LanguageContextProps,
);

const labels: { [key in allowedLanguages]: any } = {
  en: enLabels,
  de: deLabels,
};

export const LanguageContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }: React.PropsWithChildren<any>) => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const getLabel = (labelKey: string): string => {
    if (!selectedLanguage) {
      return "";
    }
    return labels[selectedLanguage as allowedLanguages][labelKey];
  };

  const setSelectedLanguageAdvanced = (newLanguage: string) => {
    setSearchParams({ lang: newLanguage });
    setSelectedLanguage(newLanguage);
    window.localStorage.setItem("lang", newLanguage);
  };

  useEffect(() => {
    if (pathname === "/") {
      return;
    }

    const languageInQueryParam = searchParams.get("lang");

    if (
      !languageInQueryParam ||
      !_allowedLanguages.includes(languageInQueryParam) ||
      !selectedLanguage ||
      selectedLanguage !== languageInQueryParam
    ) {
      const languageFromLS = window.localStorage.getItem("lang");

      const desiredLanguage =
        selectedLanguage !== languageInQueryParam &&
        Boolean(languageInQueryParam)
          ? (languageInQueryParam as string)
          : !languageFromLS
          ? getBrowserLanguage()
          : languageFromLS;

      const newLanguage = _allowedLanguages.includes(desiredLanguage)
        ? desiredLanguage
        : languageFallback;

      setSelectedLanguageAdvanced(newLanguage as string);
    }
  }, [pathname]);

  return (
    <LanguageContext.Provider
      value={{
        selectedLanguage,
        setSelectedLanguage: setSelectedLanguageAdvanced,
        getLabel,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
