import React from "react";
import { animated, PickAnimated } from "react-spring";
import { ContactForm } from "./ContactForm";
import { ContactFormCloseButton } from "./ContactFormCloseButton";

const margin = "16px";

export const ContactUsOverlay = ({ show }: { show: PickAnimated<any> }) => {
  return (
    <animated.div
      style={{
        height: `calc(100% - ${margin})`,
        width: `calc(100vw - ${margin})`,
        backgroundColor: "orange",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        zIndex: 9999,
        transform: show.to({
          range: [0, 1],
          output: [`translate(0%, -150%)`, `translate(0%, 0%)`],
        }),
        overflowY: "scroll",
      }}
    >
      <ContactForm handleClose={() => {}} />
      <ContactFormCloseButton />
    </animated.div>
  );
};
