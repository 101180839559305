import { animated, PickAnimated } from "react-spring";
import React from "react";

interface TitleProps {
  x: PickAnimated<any>;
  width: number;
  offset: number;
}

export const Title = ({ x, offset, width }: TitleProps) => {
  return (
    <animated.div
      key="main-font"
      className="main-font"
      style={{
        position: "relative",
        overflow: "hidden",
        fontWeight: 600,
        fontSize: 36,
        opacity: 0.5,
        transform: x.to({
          range: [-1, 0, 1],
          output: [
            `translate(-${
              width / 2 - offset / 2
            }px, 0px) rotate(-90deg) scale(0.7)`,
            `translate(0px, 0px) rotate(0deg) scale(1)`,
            `translate(${
              width / 2 - offset / 2
            }px, 0px) rotate(90deg) scale(0.7)`,
          ],
        }),
      }}
    >
      vienna devs
      {/*<Shadow x={x} />*/}
    </animated.div>
  );
};

interface ShadowProps {
  x: PickAnimated<any>;
}

const Shadow = ({ x }: ShadowProps) => {
  return (
    <animated.div
      key="main-font-shadow"
      className="main-font"
      style={{
        top: 0,
        position: "absolute",
        zIndex: 99,
        overflow: "hidden",
        fontWeight: 600,
        fontSize: 36,
        color: "green",
        opacity: 1,
        filter: "blur(2px)",
        transform: x.to({
          range: [-1, 0, 1],
          output: ["scale(1.5)", "scale(1)", "scale(1.5)"],
        }),
      }}
    >
      vienna devs
    </animated.div>
  );
};
