import { DecisionButton } from "./DecisionButton";
import { animated, PickAnimated } from "react-spring";
import React, { useContext } from "react";
import { colors } from "./colors";
import LanguageContext from "./contexts/LanguageContext";
import { useLocation, useNavigate } from "react-router-dom";

interface ContactFormCloseButtonProps {}

export const ContactFormCloseButton = ({}: ContactFormCloseButtonProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { getLabel } = useContext(LanguageContext);
  const backButton = getLabel("contactFormBackButton");
  return (
    <animated.div
      key={"button-container-x-button"}
      className="x-container"
      style={{
        position: "absolute",
        zIndex: 99999,
        top: 10,
        left: 16,
      }}
    >
      <DecisionButton
        fontSize={12}
        title={"< " + backButton}
        onClick={() => {
          navigate(-1);
        }}
        color={pathname.includes("/dev") ? colors.dev : colors.project}
      />
    </animated.div>
  );
};
