import React from "react";
import { animated, PickAnimated, SpringValue } from "react-spring";
import { useNavigate } from "react-router-dom";

export const Dimmer = ({ show }: { show: PickAnimated<any> }) => {
  const navigate = useNavigate();
  return (
    <animated.div
      onClick={() => {
        navigate(-1);
      }}
      style={{
        pointerEvents: show.to((value: number) =>
          value === 0 ? "none" : "auto"
        ),
        height: "100vh",
        width: "100vw",
        backgroundColor: "black",
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        opacity: show.to({
          range: [0, 1],
          output: [0, 1],
        }),
      }}
    >
      contact us
    </animated.div>
  );
};
