import { DecisionButton } from "./DecisionButton";
import { animated, PickAnimated } from "react-spring";
import React, { useContext } from "react";
import { colors } from "./colors";
import LanguageContext from "./contexts/LanguageContext";

interface DevButtonProps {
  lang: string;
  pathname: string;
  offset: number;
  x: PickAnimated<any>;
  y: PickAnimated<any>;
  z: PickAnimated<any>;
}

export const LangButton = ({
  pathname,
  offset,
  x,
  y,
  z,
  lang,
}: DevButtonProps) => {
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext);
  const isActive = selectedLanguage === lang;
  return (
    <animated.div
      key={"button-container-dev-button"}
      className="button-container"
      style={{
        // zIndex: 9999,
        //position: "absolute",
        // top: y.to({
        //   range: [-1, 0, 1],
        //   output: [
        //     height / 2 + offset * 1.75,
        //     height / 2 + offset * 1.75,
        //     0 + offset / 2,
        //   ],
        // }),
        // transform: x.to({
        //   range: [-1, 0, 1],
        //   output: [
        //     `translate(${width}px, 0px) scale(1)`,
        //     `translate(0px, 0px) scale(1)`,
        //     `translate(0px, 0px) scale(0.9)`,
        //   ],
        // }),
        marginLeft: 5,
        marginRight: 5,
      }}
    >
      <DecisionButton
        title={lang}
        onClick={() => {
          console.log("DEBUG click on language", { setSelectedLanguage, lang });
          setSelectedLanguage(lang);
        }}
        color={
          !isActive
            ? "grey"
            : pathname === "/dev"
            ? colors.dev
            : pathname === "/project"
            ? colors.project
            : undefined
        }
        style={{ cursor: "pointer", zIndex: 9, opacity: isActive ? 1 : 0.5 }}
      />
    </animated.div>
  );
};
