import { DecisionButton } from "./DecisionButton";
import { animated, PickAnimated } from "react-spring";
import React, { useContext } from "react";
import { colors } from "./colors";
import LanguageContext from "./contexts/LanguageContext";

interface CloseButtonProps {
  pathname: string;
  x: PickAnimated<any>;
  navToRoot: () => void;
  offset: number;
}

export const CloseButton = ({
  pathname,
  x,
  navToRoot,
  offset,
}: CloseButtonProps) => {
  const { getLabel } = useContext(LanguageContext);
  const backButton = getLabel("backButton");
  return (
    <animated.div
      key={"button-container-x-button"}
      className="x-container"
      style={{
        position: "absolute",
        top: 0,
        left: x.to({
          range: [-1, 0, 1],
          output: [0 + offset / 4, -130, 0 + offset / 4],
        }),
      }}
    >
      <DecisionButton
        fontSize={12}
        title={"< " + backButton}
        onClick={navToRoot}
        color={
          pathname === "/start"
            ? undefined
            : pathname === "/dev"
            ? colors.dev
            : colors.project
        }
      />
    </animated.div>
  );
};
